const notLoggedInLinks = {
  SV: {
    settings: 'https://privacysettings.schibsted.com/',
    readmore: 'https://info.privacy.schibsted.com/se/s005/'
  },
  NO: {
    settings: 'https://privacysettings.schibsted.no/',
    readmore: 'https://info.privacy.schibsted.com/no/s005/'
  },
  SE: {
    settings: 'https://privacysettings.schibsted.no/',
    readmore: 'https://info.privacy.schibsted.com/no/s005/'
  },
  DK: {
    settings: 'https://privacysettings.schibsted.dk/',
    readmore: 'https://info.privacy.schibsted.com/en/smart-ads-and-what-they-mean-for-you/'
  },
  FI: {
    settings: 'https://privacysettings.schibsted.fi/',
    readmore: 'https://info.privacy.schibsted.com/fi/alymainokset-ja-mita-ne-tarkoittavat-sinun-kannaltasi/'
  }
};

export { notLoggedInLinks };
