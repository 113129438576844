import { type LanguagesConfig } from '../interface/config';

const getLanguagesConfig = (siteName: string): LanguagesConfig => {
  return {
    title: {
      SV: 'Om den här annonsen',
      NO: 'Om denne annonsen',
      DK: 'Om denne annonce',
      FI: 'Tietoja tästä mainoksesta'
    },
    family: {
      SV: `${siteName} är en del av Schibsted-familjen.`,
      NO: `${siteName} er en del av Schibsted-familien`,
      DK: `${siteName} er en del af Schibsted-familien`,
      FI: `${siteName} on osa Schibsted-konsernia`,
      POLARIS: `${siteName} samarbeider med Schibsted-familien`
    },
    close: {
      SV: 'Stäng',
      NO: 'Lukk',
      DK: 'Luk',
      FI: 'Sulje'
    },
    why: {
      SV: 'Varför ser du denna annons?',
      NO: 'Hvorfor ser du denne annonsen?',
      DK: 'Hvorfor ser du denne annonce?',
      FI: 'Miksi tämä mainos näkyy sinulle?'
    },
    whymarketplace: {
      SV: 'Varför ser du denna reklam?',
      NO: 'Hvorfor ser du denne reklamen?',
      DK: 'Hvorfor ser du denne reklame?',
      FI: 'Miksi tämä mainos näkyy sinulle?'
    },
    whyexplained: {
      SV: 'Du ser denna annons eftersom sajten du besöker till stor del finansieras  av annonsintäkter. Vissa annonser ser du för att vi tror att de kan  intressera dig. Detta antagande baserar vi på din tidigare aktivitet på Schibsteds sajter och appar.',
      NO: 'Du ser denne annonsen ettersom nettstedet du besøker til stor del finansieres av annonseinntekter. Noen annonser ser du fordi vi tror de kan interessere deg. Vi antar dette basert på din tidligere aktivitet på Schibsteds nettsteder og apper.',
      DK: 'Du ser denne annonce eftersom siden du besøger i høj grad finansieres af annonceindtægter. Nogle annoncer vises, fordi vi tror de kan interessere dig. Vi antager dette baseret på din tidligere aktivitet på Schibsteds hjemmesider og apps.',
      FI: 'Näet tämän mainoksen, koska vierailemasi sivusto rahoitetaan suurelta osin mainostuloilla. Näet osan mainoksista, koska oletamme niiden kiinnostavan sinua. Tämän olettamus perustuu aiempaan toimintaasi Schibstedin verkkosivustoilla ja sovelluksissa.',
      POLARIS:
        'Du ser denne annonsen ettersom nettstedet du besøker til stor del finansieres av annonseinntekter. Noen annonser ser du fordi vi tror de kan interessere deg. Vi antar dette basert på din tidligere aktivitet på Schibsted og samarbeidende mediehus sine nettsteder og apper.'
    },
    manageheading: {
      SV: 'Hantera dina annonsinställningar',
      NO: 'Administrer dine annonseinnstillinger',
      DK: 'Administrer dine annonceindstillinger',
      FI: 'Muokkaa mainosasetuksiasi'
    },
    takecontrol: {
      SV: 'Styr själv över vilka annonser du ser genom att ändra dina inställningar.',
      NO: 'Du kan selv styre hvilke annonser du ser ved å endre dine innstillinger.',
      DK: 'Du kan selv styre hvilke annoncer du ser ved at ændre dine indstillinger.',
      FI: 'Voit itse hallita näkemiäsi mainoksia muuttamalla asetuksiasi.'
    },
    aboutschibsted: {
      SV: 'Om Schibsted och annonsering',
      NO: 'Om Schibsted og annonsering',
      DK: 'Om Schibsted og annoncering',
      FI: 'Tietoja mainonnasta Schibstedillä'
    },
    readmoreschibstedlinktext: {
      SV: 'Läs mer om annonsering på Schibsted',
      NO: 'Les mer om annonsering på Schibsted',
      DK: 'Læs mere om annoncering på Schibsted',
      FI: 'Lue lisää mainonnasta Schibstedillä'
    },
    smokeTest: {
      SV: '',
      NO: 'Skjul annonsen midlertidig',
      DK: '',
      FI: ''
    },
    smokeTestURL: {
      SV: '',
      NO: 'https://docs.google.com/forms/d/e/1FAIpQLSdS1Mbdz1Qw870JNRoYK3qp5u0EFta9H-NJywi-yncUc7duQg/viewform',
      DK: '',
      FI: ''
    },
    hideAd: {
      SV: 'Dölj din annons tillfälligt',
      NO: 'Skjul annonsen midlertidig',
      DK: 'Læs mere om annoncering på Schibsted',
      FI: 'Piilota mainos väliaikaisesti'
    },
    annonseId: {
      SV: 'Annonsens ID-nummer',
      NO: 'Annonsens ID-nummer',
      DK: 'Annoncens ID-nummer',
      FI: 'Ilmoituksen ID-numero'
    },
    advertiserHeader: {
      SV: 'Om annonsören',
      NO: 'Om annonsøren',
      DK: 'Om annoncøren',
      FI: 'Tietoja mainostajasta'
    },
    advertiserName: {
      SV: 'Detta är en annons för:',
      NO: 'Dette er en annonse for:',
      DK: 'Dette er en annonce for:',
      FI: 'Tämä on mainos:'
    }
  };
};

export { getLanguagesConfig };
